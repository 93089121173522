import React from "react";
import { useTranslation } from "gatsby-plugin-react-i18next";
import { DividerSm } from "../misc/style";
import artGraphic from "../../images/art-graphic.png";
import { ArtWrapper } from "./style";

const Art: React.FC = () => {
  const { t } = useTranslation("leagueDisplays2017");
  return (
    <ArtWrapper>
      <div className="columns">
        <header>
          <h2>{t("art.header.text")}</h2>
          <p>{t("art.subheader.text")}</p>
        </header>
        <div>
          <img loading="lazy" src={artGraphic} alt="League of Legends Display Art Graphic" />
        </div>
      </div>
      <DividerSm />
    </ArtWrapper>
  );
};

export default Art;
