import AprilfoolsVideoMP4 from "../../../images/splash/videos/animated-aprilfools.mp4";
import AprilfoolsVideoWEBM from "../../../images/splash/videos/animated-aprilfools.webm";
import AprilfoolsVideoPoster from "../../../images/splash/videos/posters/poster-aprilfools.jpg";
import DianaVideoMP4 from "../../../images/splash/videos/animated-bloodmoon-diana.mp4";
import DianaVideoWEBM from "../../../images/splash/videos/animated-bloodmoon-diana.webm";
import DianaVideoPoster from "../../../images/splash/videos/posters/poster-bloodmoon-diana.jpg";
import TristanaVideoMP4 from "../../../images/splash/videos/animated-dragontrainer-tristana.mp4";
import TristanaVideoWEBM from "../../../images/splash/videos/animated-dragontrainer-tristana.webm";
import TristanaVideoPoster from "../../../images/splash/videos/posters/poster-dragontrainer-tristana.jpg";
import JhinVideoMP4 from "../../../images/splash/videos/animated-jhin.mp4";
import JhinVideoWEBM from "../../../images/splash/videos/animated-jhin.webm";
import JhinVideoPoster from "../../../images/splash/videos/posters/poster-jhin.jpg";
import KindredVideoMP4 from "../../../images/splash/videos/animated-kindred.mp4";
import KindredVideoWEBM from "../../../images/splash/videos/animated-kindred.webm";
import KindredVideoPoster from "../../../images/splash/videos/posters/poster-kindred.jpg";
import BilgewaterVideoMP4 from "../../../images/splash/videos/animated-missfortune-bilgewater.mp4";
import BilgewaterVideoWEBM from "../../../images/splash/videos/animated-missfortune-bilgewater.webm";
import BilgewaterVideoPoster from "../../../images/splash/videos/posters/poster-missfortune-bilgewater.jpg";
import AsheVideoMP4 from "../../../images/splash/videos/animated-project-ashe.mp4";
import AsheVideoWEBM from "../../../images/splash/videos/animated-project-ashe.webm";
import AsheVideoPoster from "../../../images/splash/videos/posters/poster-project-ashe.jpg";
import WarwickVideoMP4 from "../../../images/splash/videos/animated-warwick.mp4";
import WarwickVideoWEBM from "../../../images/splash/videos/animated-warwick.webm";
import WarwickVideoPoster from "../../../images/splash/videos/posters/poster-warwick.jpg";
import GroupSlashes from "../../../images/splash/content-original-championillustrations-group-slashes.jpg";
import ElderwoodHecarim from "../../../images/splash/content-original-championillustrations-hecarim-elderwood-hecarim-splash.jpg";
import JhinClassic from "../../../images/splash/content-original-championillustrations-jhin-classic.jpg";
import ProjectLucian from "../../../images/splash/content-original-championillustrations-project-lucian.jpg";
import Season4 from "../../../images/splash/content-original-esports-season4-2014-worlds.jpg";
import Season5 from "../../../images/splash/content-original-esports-season5-regular-season.jpg";
import RuneterraIonia from "../../../images/splash/content-original-runeterra-ionia.jpg";
import RuneterraMounttargon from "../../../images/splash/content-original-runeterra-mounttargon.jpg";

export const galleryAssets = [
  {
    mp4: DianaVideoMP4,
    webm: DianaVideoWEBM,
    poster: DianaVideoPoster,
    image: GroupSlashes,
    alt: "Group Slashes",
  },
  {
    mp4: KindredVideoMP4,
    webm: KindredVideoWEBM,
    poster: KindredVideoPoster,
    image: ElderwoodHecarim,
    alt: "Elderwood Hecarim",
  },
  {
    mp4: JhinVideoMP4,
    webm: JhinVideoWEBM,
    poster: JhinVideoPoster,
    image: JhinClassic,
    alt: "Jhin Classic",
  },
  {
    mp4: TristanaVideoMP4,
    webm: TristanaVideoWEBM,
    poster: TristanaVideoPoster,
    image: ProjectLucian,
    alt: "Project Lucian",
  },
  {
    mp4: BilgewaterVideoMP4,
    webm: BilgewaterVideoWEBM,
    poster: BilgewaterVideoPoster,
    image: Season4,
    alt: "Season Four Worlds",
  },
  {
    mp4: AprilfoolsVideoMP4,
    webm: AprilfoolsVideoWEBM,
    poster: AprilfoolsVideoPoster,
    image: Season5,
    alt: "Season Five",
  },
  {
    mp4: AsheVideoMP4,
    webm: AsheVideoWEBM,
    poster: AsheVideoPoster,
    image: RuneterraIonia,
    alt: "Runeterra Ionia",
  },
  {
    mp4: WarwickVideoMP4,
    webm: WarwickVideoWEBM,
    poster: WarwickVideoPoster,
    image: RuneterraMounttargon,
    alt: "Runeterra Mounttargon",
  },
];
