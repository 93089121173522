import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/Layout";
import Splash from "../components/Splash/Splash";
import { Backdrop } from "../components/misc/style";
import Collections from "../components/Collections/Collections";
import Art from "../components/Art/Art";
import Displays from "../components/Displays/Displays";
import Cta from "../components/Cta/Cta";
// import { GatsbySeo } from "gatsby-plugin-next-seo";

const IndexPage: React.FC = () => {
  return (
    <Layout>
      <Splash />
      <Backdrop>
        <Collections />
        <Art />
        <Displays />
        <Cta />
      </Backdrop>
    </Layout>
  );
};
export default IndexPage;

export const query = graphql`
  query ($language: String!) {
    ...TranslationQueryFragment
  }
`;
