import styled from "styled-components";

export const DownloadsWrapper = styled.div`
  z-index: 100;
  display: none;
  @media (min-width: 768px) {
    display: block;
  }
  ul {
    list-style: none;
    padding: 0;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  }
  li {
    &:first-child {
      padding-right: 15px;
    }
  }
`;

export const DownloadButton = styled.a`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  vertical-align: middle;
  cursor: pointer;
  height: 3.75em;
  max-width: 100%;
  min-width: 220px;
  border: 0;
  color: #c8aa6e;
  fill: currentColor;
  font-family: ${(props) => props.theme.font_family_alt};
  font-weight: 700;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: relative;
  z-index: 1;
  transition: 0.15s;
  box-shadow: 0 0 13px 2px rgba(0, 0, 0, 0.6);
  padding: 20px 30px;
  font-size: 1.25rem;

  svg {
    transition: 400ms fill ease-in-out;
    margin-right: 12px;
  }

  &::before,
  &::after {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
  }

  &::before {
    background: linear-gradient(0deg, #72542a 0%, #bd9e5e 100%);
  }

  &::after {
    margin: 1px;
    background: #1e2328;
    transition: background 400ms ease-in-out;
  }

  @media (min-width: 1024px) {
    &::after {
      margin: 2px;
    }
  }

  &:hover,
  &:active,
  &:focus {
    color: #eee6d4;
    text-decoration: none;
    box-shadow: 0 0 28px #000, 0 0 28px rgba(0, 0, 0, 0.6);
    svg {
      fill: #eee6d4;
    }
  }

  &:hover::after,
  &:active::after,
  &:focus::after {
    background: #272e33;
  }
`;
