import styled, { keyframes } from "styled-components";
import { SubSection } from "../misc/style";

const scrollTopImage = keyframes`
  0% {transform: translateY(0%);}
  100% {transform: translateY(100%);}
`;

const scrollBottomImage = keyframes`
  0% {transform: translateY(-200%);}
  100% {transform: translateY(-100%);}
`;

export const CollectionsWrapper = styled(SubSection)`
  .mobileMessage {
    position: relative;
    padding: 5%;
    width: 100%;
    height: 100%;
    display: block;
    text-align: center;
    @media (min-width: 768px) {
      display: none;
    }

    h2 {
      font-size: 4vw;
      margin-bottom: 40px;
      line-height: 1.1;
      font-weight: 500;
      @media (max-width: 700px) {
        font-size: 28px;
      }
      @media (min-width: 1000px) {
        font-size: 40px;
      }
    }
  }

  .collectionFlex {
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 30px;
    @media (min-width: 768px) {
      flex-direction: row;
      padding-top: 0;
    }
    .display {
      position: relative;
      width: 78%;
      height: 470px;
      flex: 0 0 auto;
      overflow: hidden;
      @media (min-width: 768px) {
        width: 55%;
        max-width: 465px;
        height: 568px;
      }
      &::before {
        content: "";
        position: absolute;
        left: 0;
        height: 26%;
        width: 100%;
        pointer-events: none;
        overflow: hidden;
        z-index: 1;
        top: 0;
        bottom: auto;
        background: linear-gradient(to bottom, #0b131d 17%, transparent 75%);
      }
      &::after {
        content: "";
        position: absolute;
        left: 0;
        height: 26%;
        width: 100%;
        pointer-events: none;
        overflow: hidden;
        z-index: 1;
        bottom: 0;
        top: auto;
        background: linear-gradient(to top, #0e141e 6%, transparent 90%);
      }
    }
    .cardWrapper {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      background-color: #0e141e;
      z-index: 100;
    }
    .card {
      display: flex;
      flex-direction: column;
      box-shadow: 0 0 13px 2px rgb(0 0 0 / 60%);
      border: 1px solid #c8aa6e;
      span {
        white-space: nowrap;
        font-size: 0.875rem;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        vertical-align: middle;
        max-width: 100%;
        color: #c7b184;
        fill: currentColor;
        font-family: "Beaufort for LOL", serif;
        font-weight: 500;
        position: relative;
        z-index: 1;
        transition: 0.15s;
        height: 3.5em;
        @media (min-width: 480px) {
          padding: 18px 26px;
        }
        @media (min-width: 768px) {
          font-size: 1rem;
          line-height: 50px;
          padding: 22px 30px;
        }
        @media (min-width: 1500px) {
          font-size: 1rem;
        }
        &::before {
          content: "";
          display: block;
          background: #c8aa6e;
          height: 1px;
          width: 100%;
          position: absolute;
          top: 0;
        }
      }
    }
    .displayBg {
      position: relative;
      img {
        filter: saturate(70%);
        opacity: 0.9;
        will-change: transform;
        max-width: 100%;
        margin: 0;
        &:first-child {
          transform: translate3d(0, 0, 0);
          margin-bottom: 0;
          background-repeat: repeat-y;
          @media (min-width: 768px) {
            animation: ${scrollTopImage} 100s linear infinite;
          }
        }
        &:last-child {
          padding-bottom: 2%;
          transform: translate3d(0, 0, 0);
          @media (min-width: 768px) {
            display: block;
            animation: ${scrollBottomImage} 100s linear infinite;
          }
        }
      }
    }
    .displayAdjacent {
      display: flex;
      align-items: center;
      justify-content: center;
      padding-top: 12%;
      @media (min-width: 768px) {
        padding-top: 0;
      }
      header {
        text-align: center;
        @media (min-width: 768px) {
          text-align: left;
          padding: 11.5%;
        }
        h2 {
          font-size: 30px;
          letter-spacing: 1.6px;
          line-height: 1.33333;
          font-family: "Beaufort for LOL", serif;
          margin: 0 0 40px;
          color: #cdbe91;
          font-weight: 500;
          @media (min-width: 768px) {
            letter-spacing: normal;
          }
          @media (min-width: 480px) {
            font-size: 40px;
            line-height: 42px;
          }
        }
        p {
          font-size: 3.5vw;
          color: #f0e6d2;
          margin: 0 0 20px;
          line-height: 30px;
          font-weight: 500;
          @media (min-width: 685.71429px) {
            font-size: 24px;
          }
        }
      }
    }
  }
`;
