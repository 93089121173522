import React from "react";
import SplashGallery from "./SplashGallery/SplashGallery";
import Intro from "./Intro/Intro";
import { SplashWrapper } from "./style";
import { DividerLg } from "../misc/style";

const Splash: React.FC = () => {
  return (
    <SplashWrapper>
      <SplashGallery />
      <Intro />
      <DividerLg />
    </SplashWrapper>
  );
};

export default Splash;
