import styled from "styled-components";
import bgImage from "../../images/global/backgrounds/bg.png";

export const SubSection = styled.section`
  position: relative;
  width: 100%;
  max-width: 1280px;
  margin: 0 auto;
  z-index: 1;
  padding: 50px 6%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  @media (min-width: 480px) {
    padding: 50px 11%;
  }
  @media (min-width: 768px) {
    padding: 80px 20px;
  }
`;

export const DividerLg = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  z-index: 100;
  &::before {
    content: "";
    position: absolute;
    display: block;
    height: 1px;
    width: 100%;
    left: 0;
    right: 0;
    margin: 0 auto;
    background-image: linear-gradient(to left, transparent 0%, #565a5a 20%, #565a5a 50%, #565a5a 80%, transparent 100%);
    background-color: transparent;
  }
  &::after {
    content: "";
    position: absolute;
    width: 9px;
    height: 9px;
    left: 50%;
    border: 1px solid #565a5a;
    background-color: #13171a;
    transform: rotate(45deg) translateX(-50%);
  }
`;

export const DividerSm = styled(DividerLg)`
  &::before {
    content: "";
    position: absolute;
    display: block;
    height: 1px;
    width: 70%;
    max-width: 400px;
    left: 0;
    right: 0;
    margin: 0 auto;
    background-image: linear-gradient(to left, transparent 0%, #565a5a 20%, #565a5a 50%, #565a5a 80%, transparent 100%);
    background-color: transparent;
    @media (min-width: 1024px) {
      max-width: 430px;
    }
  }
  &::after {
    content: "";
    position: absolute;
    width: 9px;
    height: 9px;
    left: 50%;
    border: 1px solid #565a5a;
    background-color: #13171a;
    transform: rotate(45deg) translateX(-50%);
  }
`;

export const Backdrop = styled.div`
  background-image: linear-gradient(to top, #161c20 0%, #050c17 100%);
  background-repeat: no-repeat;
  background-size: 100%;
  position: relative;
  &::before {
    content: "";
    position: absolute;
    height: 100%;
    width: 100%;
    background-repeat: no-repeat;
    background-position: top center;
    background-size: contain;
    background-image: url(${bgImage});
    opacity: 0.75;
    z-index: 1;
  }
`;
