import React from "react";
import { useTranslation } from "gatsby-plugin-react-i18next";
import { DividerSm } from "../misc/style";
import Card from "../../images/star-guardians/star-guardian.png";
import CardBG from "../../images/global/backgrounds/sg-collection-grid.png";
import { CollectionsWrapper } from "./style";

const Collections: React.FC = () => {
  const { t } = useTranslation("leagueDisplays2017");

  return (
    <CollectionsWrapper>
      <div className="mobileMessage">
        <div className="c-downloads__mobile-wrapper u-text-center">
          <h2 className="c-heading-40">{t("downloads.unavailable.text")}</h2>
        </div>
        <DividerSm />
      </div>
      <div className="collectionFlex">
        <div className="display">
          <div className="cardWrapper">
            <div className="card">
              <img loading="lazy" src={Card} alt="Star Guardian Card" />
              <span className="c-btn c-btn--star">{t("star.links.item01.text")}</span>
            </div>
          </div>
          <div className="displayBg">
            <img
              src={CardBG}
              alt="Star Guardian Collections Grid"
              className="c-collection__img-top u-spacing-none"
              aria-hidden="true"
            />
            <img
              src={CardBG}
              alt="Star Guardian Collections Grid"
              className="c-collection__img-bottom"
              aria-hidden="true"
            />
          </div>
        </div>
        <div className="displayAdjacent">
          <header>
            <h2 className="c-section-intro__title">{t("collections.header.text")}</h2>
            <p className="c-heading-24">{t("collections.subheader.text")}</p>
          </header>
        </div>
      </div>
      <DividerSm />
    </CollectionsWrapper>
  );
};

export default Collections;
