import styled, { keyframes } from "styled-components";

const Fadein = keyframes`
  0% {opacity: 0;}
  100% {opacity: 1;}
`;

export const SplashGalleryWrapper = styled.div`
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;
  overflow: hidden;
  animation: ${Fadein} 2s linear 1s;
  animation-fill-mode: both;
  > div {
    overflow: hidden;
    height: 100%;
    width: 100%;
    max-width: 1800px;
    margin: 0 auto;
    position: relative;
    @media (min-width: 768px) {
      &::before {
        pointer-events: none;
        content: "";
        position: absolute;
        display: block;
        width: 100%;
        height: 100%;
        background-image: linear-gradient(to right, rgba(0, 0, 0, 0.9), transparent 50%),
          linear-gradient(to right, rgba(0, 0, 0, 0.4), transparent 60%);
        z-index: 10;
      }
      &::after {
        pointer-events: none;
        content: "";
        position: absolute;
        width: 100%;
        height: 100%;
        right: 0;
        left: 0;
        top: 0;
        background-image: linear-gradient(to right, black, transparent 8%, transparent 92%, black);
        z-index: 10;
      }
    }
  }
`;

export const GalleryCell = styled.div`
  left: 0 !important;
  opacity: 0;
  transition: opacity 1.8s ease-in-out;

  &.previous {
    z-index: 1;
    transition-delay: 0.3s;
  }
  &.current {
    z-index: 2;
    opacity: 1;
  }

  &.current,
  &.previous {
    video {
      position: absolute;
      right: 0;
      left: 0;
      top: 0;
      min-width: 100%;
      min-height: 100%;
      width: auto;
      height: auto;
      background-size: cover;
      overflow: hidden;
      object-fit: cover;
      display: block;
      pointer-events: none;
      mask-image: linear-gradient(to right, transparent 100%, rgba(0, 0, 0, 0.8) 15%),
        linear-gradient(to left, rgba(0, 0, 0, 0.1) 1%, rgba(0, 0, 0, 0.8) 6%);
      transition: top 0.5s ease;
      @media (max-width: 767px) {
        display: none;
      }
      @media (min-width: 1400px) {
        max-height: 1200px;
      }
    }
  }

  img {
    height: 105%;
    top: 0;
    left: 50%;
    object-fit: contain;
    display: inline-block;
    overflow: auto;
    pointer-events: none;
    transform: translateX(-50%);
    position: absolute;
    max-width: inherit;
    pointer-events: none;
    overflow: auto;
    vertical-align: middle;
    margin: 0 0 20px;
    @media (min-width: 768px) {
      display: none;
    }
  }
`;
