import styled from "styled-components";

export const SplashWrapper = styled.section`
  margin-top: 80px;
  height: 620px;
  z-index: 10;
  background-color: black;
  position: relative;
  @media (min-width: 768px) {
    height: 60vh;
    min-height: 659px;
    max-height: 1100px;
  }
`;
