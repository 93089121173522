import React, { forwardRef } from "react";
import { GalleryCell } from "./style";

interface Asset {
  mp4: string;
  webm: string;
  poster: string;
  image: string;
  alt: string;
}

interface Props {
  asset: Asset;
  className: string;
  autoPlay: boolean;
  ref: HTMLVideoElement;
}

const GalleryItem: React.ForwardRefRenderFunction<HTMLVideoElement, Props> = (
  { asset, className, autoPlay }: Props,
  ref,
) => {
  const { mp4, webm, poster, image, alt } = asset;

  return (
    <GalleryCell className={className}>
      <video ref={ref} loop muted playsInline poster={poster} autoPlay={autoPlay}>
        <source type="video/webm" src={webm} />
        <source type="video/MP4" src={mp4} />
      </video>
      <img src={image} alt={alt} />
    </GalleryCell>
  );
};

const forwardGalleryItem = forwardRef(GalleryItem);

export default forwardGalleryItem;
