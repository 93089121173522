import React from "react";
import { useTranslation } from "gatsby-plugin-react-i18next";
import Downloads from "../Splash/Intro/Downloads/Downloads";
import { CtaWrapper } from "./style";

const Cta: React.FC = () => {
  const { t } = useTranslation("leagueDisplays2017");

  return (
    <CtaWrapper>
      <Downloads />
      <p className="mobileMessage">{t("downloads.unavailable.text")}</p>
      <p className="FAQ">
        {t("cta.body.item01.text")}{" "}
        <a href={t("cta.body.item01.links.link")} rel="noreferrer" target="_blank">
          {t("cta.body.item01.links.text")}
        </a>
      </p>
    </CtaWrapper>
  );
};

export default Cta;
