import styled from "styled-components";
import { SubSection } from "../misc/style";

export const ArtWrapper = styled(SubSection)`
  .columns {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    justify-content: center;
    @media (min-width: 768px) {
      flex-direction: row;
    }
    > * {
      flex: 0 1 50%;
    }
  }
  header {
    text-align: center;
    padding: 6% 0;
    @media (min-width: 768px) {
      text-align: left;
      padding: 6%;
    }
    h2 {
      font-size: 30px;
      letter-spacing: 1.6px;
      line-height: 1.33333;
      font-family: "Beaufort for LOL", serif;
      margin: 0 0 40px;
      color: #cdbe91;
      font-weight: 500;
      @media (min-width: 768px) {
        letter-spacing: normal;
      }
      @media (min-width: 480px) {
        font-size: 40px;
        line-height: 42px;
      }
    }
    p {
      font-size: 3.5vw;
      color: #f0e6d2;
      margin: 0 0 20px;
      line-height: 30px;
      font-weight: 500;
      @media (min-width: 685.71429px) {
        font-size: 24px;
      }
    }
  }
`;
