import styled from "styled-components";
import { SubSection } from "../misc/style";

export const CtaWrapper = styled(SubSection)`
  font-family: ${(props) => props.theme.font_family_alt};
  font-size: 16px;
  line-height: 1.22222;
  font-weight: 500;
  color: ${(props) => props.theme.color_heading_2};
  .mobileMessage {
    position: relative;
    padding: 5%;
    width: 100%;
    height: 100%;
    display: block;
    text-align: center;
    @media (min-width: 768px) {
      display: none;
    }
  }
  .FAQ {
    @media (min-width: 768px) {
      margin-top: 20px;
    }
  }
`;
