import React, { useRef, useEffect, useState } from "react";
import { galleryAssets } from "./GalleryAssets";
import { SplashGalleryWrapper } from "./style";
import GalleryItem from "./GalleryItem";

const SplashGallery: React.FC = () => {
  const [current, setCurrent] = useState(0);
  const [previous, setPrevious] = useState(galleryAssets.length - 1);
  const videoRefs = useRef<HTMLVideoElement[] | null>([]);
  videoRefs.current = [];

  const addVideoRef = (el: HTMLVideoElement) => {
    if (el && !videoRefs.current?.includes(el)) {
      videoRefs.current?.push(el);
    }
  };

  useEffect(() => {
    const loop = setInterval(() => {
      setCurrent((prevVid) => {
        const nextVid = prevVid < galleryAssets.length - 1 ? prevVid + 1 : 0;
        setPrevious(prevVid);
        videoRefs.current?.forEach((vid, index) => {
          if (nextVid === index) {
            const playReady =
              vid.currentTime == 0 && vid.paused && !vid.ended && vid.readyState > vid.HAVE_CURRENT_DATA;
            if (playReady) {
              vid.play();
            }
          } else if (prevVid === index) {
            setTimeout(() => {
              vid.pause();
              vid.currentTime = 0;
            }, 1800);
          } else {
            vid.pause();
            vid.currentTime = 0;
          }
        });
        return prevVid + 1 === galleryAssets.length ? 0 : prevVid + 1;
      });
    }, 8000);
    return () => clearInterval(loop);
  }, []);

  return (
    <SplashGalleryWrapper>
      <div>
        {galleryAssets.map((asset, index) => {
          return (
            <GalleryItem
              asset={asset}
              className={`${index === current ? "current" : ""}${index === previous ? "previous" : ""}`}
              autoPlay={index === 0}
              ref={addVideoRef}
              key={index}
            />
          );
        })}
      </div>
    </SplashGalleryWrapper>
  );
};

export default SplashGallery;
