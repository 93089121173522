import React from "react";
import { useTranslation } from "gatsby-plugin-react-i18next";
import Downloads from "./Downloads/Downloads";
import { IntroContainer } from "./style";
import logo from "../../../images/displays-logo.png";

const Intro: React.FC = () => {
  const { t } = useTranslation("leagueDisplays2017");

  return (
    <IntroContainer>
      <div className="introWrapper">
        <div className="intoflex">
          <div className="logoWrapper">
            <img src={logo} width={256} height={256} alt="League of Legends Display Logo" />
          </div>
          <header>
            <h1 className="c-intro__title c-heading-60 u-text-bold u-text-black-shadow">{t("splash.header.text")}</h1>
            <h2 className="c-intro__subtitle c-heading-40 u-text-pearl u-text-black-shadow">
              {t("splash.subheader.item01.text")}
            </h2>
          </header>
          <Downloads />
        </div>
      </div>
    </IntroContainer>
  );
};

export default Intro;
