import styled from "styled-components";
import { SubSection } from "../misc/style";
import monitorTall from "../../images/global/backgrounds/stack-monitor.png";
import monitorWide from "../../images/global/backgrounds/wide-monitor.png";

export const DisplaysWrapper = styled(SubSection)`
  .columns {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    justify-content: center;
    @media (min-width: 768px) {
      flex-direction: row;
    }
    > * {
      flex: 0 1 50%;
    }
  }
  header {
    text-align: center;
    h2 {
      font-size: 30px;
      letter-spacing: 1.6px;
      line-height: 1.33333;
      font-family: "Beaufort for LOL", serif;
      margin: 0 0 40px;
      color: #cdbe91;
      font-weight: 500;
      @media (min-width: 768px) {
        letter-spacing: normal;
      }
      @media (min-width: 480px) {
        font-size: 40px;
        line-height: 42px;
      }
    }
    p {
      font-size: 3.5vw;
      color: #f0e6d2;
      margin: 0 0 20px;
      line-height: 30px;
      font-weight: 500;
      @media (min-width: 685.71429px) {
        font-size: 24px;
      }
    }
  }
  .previewDisplays {
    max-width: 770px;
    padding: 60px 0 70px;
    position: relative;
    align-items: center;
    justify-content: center;
    * {
      position: relative;
    }
    display: flex;
    flex-direction: column;
    @media (min-width: 640px) {
      flex-direction: row;
    }
  }
  .displayTall {
    padding: 0 15px;
    width: 210px;
    height: 305px;

    &::before {
      pointer-events: none;
      content: "";
      position: absolute;
      top: -3%;
      left: 7px;
      width: 93%;
      height: 121%;
      background-image: url(${monitorTall});
      background-repeat: no-repeat;
      background-position: top center;
      background-size: 100%;
      z-index: 10;
    }

    .previewWrapper {
      height: 152px;
      width: 180px;
      overflow: hidden;
    }
  }
  .displayWide {
    margin-top: 90px;
    padding: 0 8px;

    @media (min-width: 580px) {
      display: block;
    }

    @media (min-width: 920px) {
      display: block;
      margin-left: 30px;
    }

    &::before {
      pointer-events: none;
      content: "";
      position: absolute;
      top: -5.6%;
      left: 50%;
      right: 0;
      width: 98%;
      height: 145%;
      transform: translateX(-50%);
      background-image: url(${monitorWide});
      background-repeat: no-repeat;
      background-position: top center;
      background-size: 100%;
      z-index: 10;
    }

    .flexCenter {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .previewWrapper {
      height: 23vw;
      width: 32.5vw;
      max-height: 182px;
      max-width: 250px;
      overflow: hidden;
    }
  }

  .previewTransitions {
    > div {
      position: absolute;
      left: 0 !important;
      opacity: 0;
      transition: opacity 3.3s ease-in-out, visibility 0s ease-in-out 3s;
      z-index: 0;
      text-align: center;
      width: 100%;
      height: 100%;
      pointer-events: none;

      &.previous {
        z-index: 1;
        &:nth-child(odd) {
          img {
            transform: translateX(-43%);
          }
        }
        &:nth-child(even) {
          img {
            transform: translateX(-57%);
          }
        }
      }

      &.current {
        opacity: 1;
        z-index: 2;
        &:nth-child(odd) {
          img {
            transform: translateX(-43%);
          }
        }
        &:nth-child(even) {
          img {
            transform: translateX(-57%);
          }
        }
      }
      &:nth-child(odd) {
        img {
          left: 50%;
          transform: translateX(-57%);
        }
      }
      &:nth-child(even) {
        img {
          left: 50%;
          transform: translateX(-43%);
        }
      }

      img {
        position: absolute;
        top: 0;
        left: 0%;
        max-width: none;
        overflow: auto;
        pointer-events: none;
        transform: translateX(0%);
        width: auto;
        height: 100%;
        will-change: transform;
        transition: transform 12s cubic-bezier(0.42, 0, 0.59, 1.01);
      }
    }
  }
`;
