import styled from "styled-components";

export const IntroContainer = styled.div`
  padding: 50px 6%;
  margin: 0 auto;
  width: 100%;
  height: 100%;
  z-index: 10;
  max-width: 1280px;
  position: relative;
  @media (min-width: 480px) {
    padding: 50px 11%;
  }
  @media (min-width: 768px) {
    padding: 80px 0;
    width: 90%;
  }
  * {
    position: relative;
  }
  .introWrapper {
    position: absolute;
    bottom: 40px;
    @media (min-width: 768px) {
      bottom: 80px;
      width: 100%;
      left: 0;
      padding: 0 20px;
    }
  }
  .intoflex {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    z-index: 10;
  }
  .logoWrapper img {
    margin-bottom: 50px;
    vertical-align: middle;
    border-style: none;
    position: relative;
  }
  header {
    @media (max-width: 767px) {
      padding-right: 10vw;
    }
  }
  h1 {
    font-weight: 700;
    margin-bottom: 30px;
    font-size: 5vw;
    text-shadow: -1px 0 5px rgb(0 0 0 / 45%);
    filter: drop-shadow(0 0 15px #001e35);
    line-height: 42px;
    @media (max-width: 800px) {
      font-size: 40px;
    }
    @media (min-width: 1200px) {
      font-size: 60px;
    }
  }
  h2 {
    font-size: 4vw;
    line-height: 1.1;
    font-weight: 500;
    color: ${(props) => props.theme.color_heading_2}!important;
    margin: 0;
    text-shadow: -1px 0 5px rgb(0 0 0 / 45%);
    filter: drop-shadow(0 0 15px #001e35);
    @media (min-width: 768px) {
      margin-bottom: 40px;
    }
    @media (max-width: 700px) {
      font-size: 28px;
    }
    @media (min-width: 1000px) {
      font-size: 40px;
    }
  }
`;
