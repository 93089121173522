import React from "react";

import previewImage001 from "../../images/displays/content-original-championillustrations-soraka-starguardian.jpg";
import previewImage002 from "../../images/displays/champion-xayahrakan-splash.jpg";
import previewImage003 from "../../images/displays/champion-vi-lunarrevel-splash.jpg";
import previewImage004 from "../../images/displays/champion-veigar-finalboss-splash.jpg";
import previewImage005 from "../../images/displays/champion-thresh-splash.jpg";
import previewImage006 from "../../images/displays/champion-teemo-splash.jpg";
import previewImage007 from "../../images/displays/champion-missfortune-captainfortune-splash.jpg";
import previewImage008 from "../../images/displays/champion-lux-elementalist-splash.jpg";
import previewImage009 from "../../images/displays/champion-leona-splash.jpg";
import previewImage010 from "../../images/displays/champion-kayn-splash.jpg";
import previewImage011 from "../../images/displays/champion-katarina-project-splash.jpg";
import previewImage012 from "../../images/displays/champion-kalista-bloodmoon-splash.jpg";
import previewImage013 from "../../images/displays/champion-janna-splash.jpg";
import previewImage014 from "../../images/displays/champion-garen-splash.jpg";
import previewImage015 from "../../images/displays/champion-darius-splash.jpg";
import previewImage016 from "../../images/displays/champion-darius-comic-image.jpg";
import previewImage017 from "../../images/displays/champion-brand-arcade-splash.jpg";
import previewImage018 from "../../images/displays/champion-azir-splash.jpg";
import previewImage019 from "../../images/displays/champion-ashe-championship-splash.jpg";

export const previewAssets: string[] = [
  previewImage001,
  previewImage002,
  previewImage003,
  previewImage004,
  previewImage005,
  previewImage006,
  previewImage007,
  previewImage008,
  previewImage009,
  previewImage010,
  previewImage011,
  previewImage012,
  previewImage013,
  previewImage014,
  previewImage015,
  previewImage016,
  previewImage017,
  previewImage018,
  previewImage019,
];

export const shuffedPreviewAssets: string[] = [
  previewImage013,
  previewImage011,
  previewImage009,
  previewImage019,
  previewImage017,
  previewImage015,
  previewImage005,
  previewImage016,
  previewImage003,
  previewImage001,
  previewImage006,
  previewImage004,
  previewImage002,
  previewImage018,
  previewImage007,
  previewImage014,
  previewImage012,
  previewImage010,
  previewImage008,
];

interface Props {
  current: number;
}

export const PreviewImages: React.FC<Props> = ({ current }: Props) => {
  return (
    <>
      {previewAssets.map((src, index) => {
        return (
          <div
            key={index}
            className={`${index === current ? "current" : ""}${index === current - 1 ? "previous" : ""}`}
          >
            <img src={src} alt="" aria-hidden="true" />
          </div>
        );
      })}
    </>
  );
};

export const PreviewImagesReverse: React.FC<Props> = ({ current }: Props) => {
  return (
    <>
      {shuffedPreviewAssets.map((src: string, index: number) => {
        return (
          <div
            key={index}
            className={`${index === current ? "current" : ""}${index === current - 1 ? "previous" : ""}`}
          >
            <img src={src} alt="" aria-hidden="true" />
          </div>
        );
      })}
    </>
  );
};
